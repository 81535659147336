import React, {useState} from 'react';
import {useOutletContext} from 'react-router-dom';
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {srvAddRbacRule, srvDeleteRbacRule} from '../../requests';

function AccessControl(props) {
  const [rbacResource, setRbacResource] = useState('');
  const [rbacAction, setRbacAction] = useState('');
  const [rbacRole, setRbacRole] = useState('');
  const [rbacQuota, setRbacQuota] = useState(1000);

  const [dialogOpen, setDialogOpen] = useState(false);

  const { sneakbar } = useOutletContext();

  function addRbacRule() {
    if (!rbacResource || !rbacAction || !rbacRole || !rbacQuota) return;
    const quota = parseInt(rbacQuota);
    if (isNaN(quota)) return;

    srvAddRbacRule(rbacResource, rbacAction, rbacRole, quota).then(response => {
      props.refreshRbacRules();
      setDialogOpen(false);
    }).catch(error => {
      sneakbar.setMessage(error.message || 'Failed to add RBAC rule');
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
    });
  }

  function deleteRbacRule(rule) {
    srvDeleteRbacRule(rule).then(response => {
      props.refreshRbacRules();
    }).catch(error => {
      sneakbar.setMessage(error.message || 'Failed to delete RBAC rule');
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
    });
  }

  function onClickAddNew(event) {
    setDialogOpen(true);
  }

  function onCloseDialog(event) {
    setDialogOpen(false);
  }

  return (
    <>
      <Dialog onClose={onCloseDialog} open={dialogOpen}>
        <DialogTitle sx={{textAlign: 'center'}}>Add new</DialogTitle>
        <DialogContent sx={{minWidth: '400px', display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <TextField
            fullWidth
            value={rbacResource}
            onChange={e => setRbacResource(e.target.value)}
            label='Resource'
            variant='outlined'
            size='small'
            sx={{marginTop: '10px', '& focus': { border: 'none' }}}
          />
          <TextField
            fullWidth
            value={rbacAction}
            onChange={e => setRbacAction(e.target.value)}
            label='Action'
            variant='outlined'
            size='small'
          />
          <TextField
            fullWidth
            value={rbacRole}
            onChange={e => setRbacRole(e.target.value)}
            label='Role'
            variant='outlined'
            size='small'
          />
          <TextField
            type='number'
            fullWidth
            value={rbacQuota}
            onChange={e => setRbacQuota(e.target.value)}
            label='Monthly quota'
            variant='outlined'
            size='small'
          />

          <Button variant='contained' onClick={addRbacRule} style={{textTransform: 'none'}}>Save</Button>
          <Button variant='outlined' onClick={onCloseDialog} style={{textTransform: 'none'}}>Cancel</Button>
        </DialogContent>
      </Dialog>

      <Button onClick={onClickAddNew} startIcon={<AddIcon />} style={{backgroundColor: 'white'}} variant='outlined'>Create rule</Button>

      <TableContainer component={Paper} sx={{ boxShadow: 0, borderRadius: '20px' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>

            <TableRow sx={{ 'td, th': { border: 0 } }}>
              <TableCell align='left' sx={{fontWeight: 'bold'}}>Resource</TableCell>
              <TableCell align='left' sx={{fontWeight: 'bold'}}>Action</TableCell>
              <TableCell align='left' sx={{fontWeight: 'bold'}}>Role</TableCell>
              <TableCell align='left' sx={{fontWeight: 'bold'}}>Properties</TableCell>
              <TableCell align='left' sx={{fontWeight: 'bold'}}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.rbacRules.map((rule, index) => (
              <TableRow key={index} sx={{ 'td, th': { border: 0 } }}>
                <TableCell component='th' scope='row'>{rule.resource}</TableCell>
                <TableCell align='left'>{rule.action}</TableCell>
                <TableCell align='left'>{rule.role}</TableCell>
                <TableCell align='left'>{JSON.stringify(rule.other_info)}</TableCell>
                <TableCell align='left' sx={{ padding: 0 }}>
                  <IconButton onClick={() => {
                    setRbacResource(rule.resource);
                    setRbacAction(rule.action);
                    setRbacRole(rule.role);
                    setRbacQuota(rule.other_info.quota);
                    setDialogOpen(true);
                  }}>
                    <EditIcon />
                  </IconButton>

                  <IconButton onClick={() => {
                    if (window.confirm(`Remove access to ${rule.resource}/${rule.action} for ${rule.role}?`)) {
                      deleteRbacRule(rule);
                    }
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
    </>
  );
}

export default AccessControl;