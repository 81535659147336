import Keycloak from 'keycloak-js';

const config = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  url: process.env.REACT_APP_KEYCLOAK_URL
};

const _kc = new Keycloak(config);

export default _kc;
