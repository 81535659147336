import styles from '../../styles/ProjectPages.module.scss';

function WaybackMachine(props) {
  return (
    <div className={styles.project}>
      <h1>Wayback Machine</h1>

      <p>
        View and inspect darknet resources historically, as they apperead in our crawling history.
      </p>
    </div>
  );
}

export default WaybackMachine;