import {useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';
import {Box, Button, TextField} from '@mui/material';

import {getAllUsers, sendNotification} from '../../requests';
import {BORDER_RADIUS} from '../../settings';

function Notifications(props) {
  const [notificationTo, setNotificationTo] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDescription, setNotificationDescription] = useState('');

  const [users, setUsers] = useState([]);

  const { sneakbar } = useOutletContext();

  function onClickSendNotification(event) {
    if (!notificationTo || !notificationTitle || !notificationDescription) return;

    const user = users.find(user => user.email === notificationTo[0]);

    const data = {
      to: [user.id],
      title: notificationTitle,
      description: notificationDescription
    };
    sendNotification(data).then(response => {
      console.log(JSON.stringify(response, null, 2))
      setNotificationTo([]);
      setNotificationTitle('');
      setNotificationDescription('');

      sneakbar.setMessage('Notification sent!');
      sneakbar.setSeverity('success');
      sneakbar.setOpen(true);
    }).catch(error => {
      sneakbar.setMessage(`Couldn't send the notification`);
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
    });
  }

  useEffect(() => {
    getAllUsers().then(response => {
      console.log(JSON.stringify(response, null, 2))
      setUsers(response);
    }).catch(error => {
      sneakbar.setMessage(`Couldn't fetch the users`);
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
    });
  }, []);

  return (
    <>
      <Box sx={{backgroundColor: '#ffffff', width: '100%', borderRadius: '20px'}}>
        <TextField
          fullWidth
          value={notificationTo}
          onChange={e => setNotificationTo([e.target.value])}
          placeholder='To'
          variant='outlined'
          sx={{'& fieldset': { border: 'none' }}}
          size='small'
          inputProps={{style: { paddingLeft: '20px' }}}
        />
      </Box>
      <Box sx={{backgroundColor: '#ffffff', width: '100%', borderRadius: '20px'}}>
        <TextField
          fullWidth
          value={notificationTitle}
          onChange={e => setNotificationTitle(e.target.value)}
          placeholder='Title'
          variant='outlined'
          sx={{'& fieldset': { border: 'none' }}}
          size='small'
          inputProps={{style: { paddingLeft: '20px' }}}
        />
      </Box>
      <Box sx={{backgroundColor: '#ffffff', width: '100%', borderRadius: '20px'}}>
        <TextField
          value={notificationDescription}
          onChange={e => setNotificationDescription(e.target.value)}
          placeholder='Description'
          inputProps={{style: { paddingLeft: '5px' }}}
          sx={{height: '100%', bgcolor: 'background.paper', '& fieldset': { border: 'none' }, boxShadow: 2, borderRadius: BORDER_RADIUS}}
          fullWidth
          multiline
          rows='9'
        />
      </Box>
      <Button onClick={onClickSendNotification} variant='contained' sx={{width: '150px', bgcolor: '#00C1BE', '&:hover': {bgcolor: '#00C1BE', boxShadow: 0}, textTransform: 'none', borderRadius: BORDER_RADIUS, boxShadow: 0}}>send</Button>
    </>
  );
}

export default Notifications;