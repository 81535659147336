import { Button } from '@mui/material';

import { BORDER_RADIUS } from '../settings';

import styles from '../styles/PageNotFound.module.scss';

function PageNotFound(props) {
  function onClickBackHome(event) {
    window.location.href = '/';
  }

  return (
    <div className={styles.pageNotFound}>
      <h1>404 PAGE NOT FOUND</h1>

      <Button onClick={onClickBackHome} variant='contained' sx={{bgcolor: '#00C1BE', '&:hover': {bgcolor: '#00C1BE'}, textTransform: 'none', borderRadius: BORDER_RADIUS, boxShadow: 1}}>Back home</Button>
    </div>
  );
}

export default PageNotFound;