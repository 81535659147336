import React from 'react';

import bitdefenderLogo from '../assets/LogoBitdefender.png';

import styles from '../styles/Layout.module.scss';

function Footer(props) {
  return (
    <div className={styles.footer}>
      <img src={bitdefenderLogo} alt='Bitdefender logo'/>

      <a href='/contact'>Contact</a>
      <a href='/'>Privacy Policy</a>
    </div>
  );
}

export default Footer;