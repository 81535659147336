import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { TextField, Box, Tooltip, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import Footer from './Footer';
import {encodeData} from '../requests';
import {
  ATHENA_URL,
  types as allTypes,
  BORDER_RADIUS,
  PAGES,
  ATHENA_PROJECT_NAME,
  UTI_PROJECT_NAME,
  WBM_PROJECT_NAME, WAYBACK_MACHINE_URL, UTI_URL, UTI_RESULTS_PER_PAGE
} from '../settings';

import styles from '../styles/Home.module.scss';

function Home(props) {
  const [input, setInput] = useState('');
  const [items, setItems] = useState([]);

  const { projects } = useOutletContext();
  const availableProjects = useRef({});

  useEffect(() => {  // TODO
    availableProjects.current[ATHENA_PROJECT_NAME] = true;
    availableProjects.current[UTI_PROJECT_NAME] = true;
    availableProjects.current[WBM_PROJECT_NAME] = true;
  }, []);

  useEffect(() => {
    if (input.trim() === '') {
      setItems([]);
      return;
    }

    const timer = setTimeout(
      () => {
        const matchedTypes = Object.keys(allTypes).filter(t => allTypes[t]?.match(input));
        if (!matchedTypes) return;

        let newItems = [];

        if (matchedTypes.includes('ip')) {
          newItems.push({
            item: <p>Search as <span>ip</span> in <span>VPN and Tor exit nodes database</span></p>,
            source: ATHENA_URL + '/case/' + encodeURIComponent(new Date().toISOString()) + '?value=' + encodeURIComponent(input) + '&type=ip&ms=vpn',
          });
        }

        for (let type in allTypes) {
          if (!matchedTypes.includes(type)) continue;

          if (type === 'onion' || type === 'url') {
            if (availableProjects.current[WBM_PROJECT_NAME]) {
              encodeData(input).then(encoded => {
                setItems(items => ([{
                  item: <p>Search as <span>{allTypes[type].name}</span> in <span>Wayback Machine</span></p>,
                  source: WAYBACK_MACHINE_URL + '/view?hash=' + encodeURIComponent(encoded) + '&time=' + encodeURIComponent((new Date()).toISOString())
                }].concat(items)));
              });
            }
          }

          if (availableProjects.current[ATHENA_PROJECT_NAME]) {
            newItems.push({
              item: <p>Search as <span>{allTypes[type].name}</span> in <span>Athena</span></p>,
              source: ATHENA_URL + '/case/' + encodeURIComponent(new Date().toISOString()) + '?value=' + encodeURIComponent(input) + '&type=' + encodeURIComponent(type),
            });
          }

          if (availableProjects.current[UTI_PROJECT_NAME] && allTypes[type].name === 'term') {
            const lastYear = new Date(new Date().setMonth(new Date().getMonth() - 6)).getTime();
            newItems.push({
              item: <p>Search as <span>{allTypes[type].name}</span> in <span>Underground Threat Intelligence</span></p>,
              source: UTI_URL + '/v2/search?q=' + encodeURIComponent(input) + '&page=1&size=' + UTI_RESULTS_PER_PAGE + '&start_date=' + encodeURIComponent(lastYear) + '&end_date=' + encodeURIComponent(Date.now()),
            });
          }
        }

        setItems(newItems);
      },
      500
    );

    return () => clearTimeout(timer);
  }, [input]);

  return (
    <>
      <div className={styles.home}>
        <div className={styles.header}>
          <h1>LEAP</h1>
          <h2>A place for all cyber investigators, with access to high value data and LEA eyes only restricted intelligence gathered in years of investigations and forensics endeavors.</h2>

          <Box sx={{position: 'relative', width: '60%', bgcolor: 'background.paper', marginTop: '20px', borderRadius: BORDER_RADIUS ,boxShadow: 1}}>
            <TextField
              fullWidth
              value={input}
              onChange={e => setInput(e.target.value)}
              placeholder='Search'
              variant='outlined'
              sx={{'& fieldset': { border: 'none' }}}
              size='small'
              inputProps={{style: { paddingLeft: '20px' }}}
            />
            {Object.keys(items).length !== 0 &&
              <List sx={{position: 'absolute', width: '100%', maxHeight: '150px', marginTop: '5px', overflow: 'auto', bgcolor: 'white', borderRadius: BORDER_RADIUS, boxShadow: 3}}>
                {items.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <Tooltip title={item.source} placement='left'>
                      <ListItemButton onClick={() => window.open(item.source, '_blank')}>
                        <ListItemText primary={item.item}/>
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            }
          </Box>
        </div>

        <div className={styles.projects}>
          {projects.map((project, index) => (
            <Box key={index} className={styles.project} sx={{boxShadow: 3}}>
              <h3>
                {project.name}
                {project.link && <OpenInNewRoundedIcon onClick={() => {window.open(project.link, '_blank');}} sx={{color: '#006DFF'}}/>}
              </h3>
              <p>{project.description}</p>
              <a href={PAGES[project.name]}>learn more</a>
            </Box>
          ))}
        </div>
      </div>

      <Footer/>
    </>
  );
}

export default Home;