import { useEffect } from 'react';
import { Alert as MuiAlert, AlertTitle, Collapse, Box } from '@mui/material';

/**
 * @param {boolean} props.open
 * @param {function} props.setOpen
 * 
 * @param {string} props.severity
 * @param {string} props.message
*/
function Alert(props) {
  
  function onClose() {
    props.setOpen(false);
  }

  function getTitle() {
    return props.severity.charAt(0).toUpperCase() + props.severity.slice(1);
  }

  useEffect(() => {
    if (!props.open || props.severity === 'error') return;
    const timeId = setTimeout(() => props.setOpen(false), 3000);
    return () => clearTimeout(timeId);
  }, [props, props.open]);

  return (
    <Box sx={{ width: '100%', position: 'absolute'}}>
      <Collapse in={props.open}>
        <MuiAlert onClose={onClose} severity={props.severity} sx={{ width: '100%' }}>
          <AlertTitle>{getTitle()}</AlertTitle>
          {props.message}
        </MuiAlert>
      </Collapse>
    </Box>
  );
}

export default Alert;
