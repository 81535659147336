import {useEffect, useRef, useState} from 'react';
import {
  Dialog, DialogContent, DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

function Feedback(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});

  const descriptionElementRef = useRef(null);

  function onClickFeedback(feedbackRow) {
    return (event) => {
      setDialogContent(feedbackRow);
      setDialogOpen(true);
    };
  }

  function onCloseDialog(event) {
    setDialogOpen(false);
  }

  useEffect(() => {
    if (!dialogOpen || !descriptionElementRef.current) return;

    descriptionElementRef.current.focus();
  }, [dialogOpen]);

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: 0, borderRadius: '20px' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow sx={{ 'td, th': { border: 0 } }}>
              <TableCell sx={{fontWeight: 'bold'}}>User</TableCell>
              <TableCell align='left' sx={{fontWeight: 'bold'}}>Title</TableCell>
              <TableCell align='left' sx={{fontWeight: 'bold'}}>Description</TableCell>
              <TableCell align='left' sx={{fontWeight: 'bold'}}>Attachments</TableCell>
              <TableCell align='left' sx={{fontWeight: 'bold'}}>Sent at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.feedback.map((row, index) => (
              <TableRow key={index} hover={true} onClick={onClickFeedback(props.feedback[index])} sx={{ 'td, th': { border: 0 }, cursor: 'pointer'}}>
                <TableCell component='th' scope='row'>{row.user}</TableCell>
                <TableCell component='th' scope='row'>{row.title}</TableCell>
                <TableCell align='left'>
                  {row.description.length > 50 ?
                    (row.description.substring(0, 47) + '...') :
                    row.description
                  }
                </TableCell>
                <TableCell align='left'>{row.attachments || '-'}</TableCell>
                <TableCell align='left'>{row.sentAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={dialogOpen} onClose={onCloseDialog} scroll='paper' fullWidth>
        <DialogTitle>{dialogContent.title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText ref={descriptionElementRef} tabIndex={-1}>{dialogContent.description}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Feedback;