import styles from '../../styles/ProjectPages.module.scss';

function Athena(props) {
  return (
    <div className={styles.project}>
      <h1>Athena</h1>

      <p>
        A private investigator that queries, correlates and augments data as it`s being added to a graph.
        <br/>
        Data can be filtered by time and the graph automatically adjusts to reflect the new timeframe.
        <br/><br/>
        Graphs can be saved and loaded in the cloud or locally exported and imported.
        <br/>
        Automated workflows can be visually defined to instruct Athena to regularly perform automated tasks and raise alerts whenever something triggers.
        <br/><br/>
        New microservices can be defined by the user and queried automatically to enrich the data with custom information available in client defined databases.
      </p>
    </div>
  );
}

export default Athena;