import React, { useEffect, useRef, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
  CircularProgress,
  Box,
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider, Dialog, DialogTitle, DialogContent, DialogContentText
} from '@mui/material';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';

import {
  getProjects,
  checkAccount,
  getEmail,
  getName,
  getNotifications,
  logout,
  isMaster,
  isPendingAccount, markNotificationSeen
} from '../requests';
import { BORDER_RADIUS } from '../settings';
import Alert from '../components/Alert';
import bitdefenderLogo from '../assets/LogoBitdefender.png';

import styles from '../styles/Layout.module.scss';
import {cutStr} from "../utils";

function getNameInitials(name) {
  const array = name.split(' ');
  const initials = (array.length === 1) ? array[0][0] : array[0][0] + array[array.length - 1][0];
  return initials.toUpperCase();
}

function Layout(props) {
  const [projects, setProjects] = useState([]);

  const [showAccountList, setShowAccountList] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [badgeContent, setBadgeContent] = useState(0);
  const notificationsRef = useRef();
  const avatarRef = useRef();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [severitySnackbar, setSeveritySnackbar] = useState('info');

  const [openModal, setOpenModal] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const descriptionElementRef = useRef(null);

  function onClickAvatar() {
    setShowAccountList(showAccountList => !showAccountList);
  }

  function onClickNotifications() {
    setShowNotifications(showNotifications => !showNotifications);
    setBadgeContent(0);
    setNotifications(notifications => {
      notifications.map(notification => {
        markNotificationSeen(notification.id);
        return {...notification, seen: true}
      });
      return notifications;
    });
  }

  function onClickNotificationItem(notification) {
    return (event) => {
      setDialogContent(notification);
      setDialogOpen(true);
    };
  }

  function onCloseDialog(event) {
    setDialogOpen(false);
  }

  useEffect(() => {
    getProjects().then(projects => {
      setProjects(projects);
    }).catch(error => {
      setMessageSnackbar(error.response?.data?.error_message || error.message || 'Failed to fetch the projects');
      setSeveritySnackbar('error');
      setOpenSnackbar(true);
    });
  }, []);

  useEffect(() => {
    getNotifications().then(notifications => {
      // console.log('notifications ' + JSON.stringify(notifications, null, 2))
      setNotifications(notifications.reverse());
      setBadgeContent(notifications.filter(notification => !notification.seen).length);
    }).catch(error => {
      setMessageSnackbar(error.response?.data?.error_message || error.message || 'Failed to fetch the notifications');
      setSeveritySnackbar('error');
      setOpenSnackbar(true);
    });
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notificationsRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (avatarRef.current && !avatarRef.current.contains(event.target)) {
        setShowAccountList(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [avatarRef]);

  useEffect(() => {
    if (!dialogOpen || !descriptionElementRef.current) return;

    descriptionElementRef.current.focus();
  }, [dialogOpen]);

  useEffect(() => {
    if (!isPendingAccount()) return;

    setOpenModal(true);
    checkAccount().then(response => {
      setOpenModal(false);
    }).catch(error => {
      setMessageSnackbar(error.response?.data?.error_message || error.message || 'Failed to check account.');
      setSeveritySnackbar('error');
      setOpenSnackbar(true);
    });
    // getNotifications().then(notifications => {
    //   setNotifications(notifications);
    //   setBadgeContent(notifications.length);
    // }
    // ).catch(error => {
    //   setMessageSnackbar(error.response?.data?.error_message || error.message || 'Failed to fetch notifications.');
    //   setSeveritySnackbar('error');
    //   setOpenSnackbar(true);
    // });
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.navbar}>
        <img src={bitdefenderLogo} alt='Bitdefender logo' onClick={() => window.location.pathname = '/'}/>

        <div className={styles.links}>
          <Link to='/'>Home</Link>
          <Link to='/contact'>Contact</Link>
          <Badge badgeContent={badgeContent} color='secondary' sx={{'.MuiBadge-badge': {bgcolor: '#FF0000'}, marginRight: '20px', position: 'relative'}} onClick={onClickNotifications}>
            <CircleNotificationsIcon color='action' sx={{width: 39, height: 39, color: 'white'}}/>
          </Badge>
          {showNotifications && 
            <List ref={notificationsRef} sx={{maxWidth: '300px', minWidth: '240px', maxHeight: '672px', overflow: 'auto', position: 'absolute', right: '55px', top: '70px', bgcolor: 'white', zIndex: '10', borderRadius: BORDER_RADIUS, boxShadow: 3}}>
              {notifications.length === 0 ?
                <ListItem disablePadding>
                  <ListItemButton disabled>
                    <ListItemText primary='When you get a notification, it will be displayed here.' />
                  </ListItemButton>
                </ListItem> :
                notifications.map((notification, index, array) => (
                  <div key={index}>
                    <ListItem disablePadding onClick={onClickNotificationItem(notification)}>
                      <ListItemButton>
                        <ListItemText primary={cutStr(notification.title, 35)} secondary={cutStr(notification.description, 40)}/>
                      </ListItemButton>
                    </ListItem>
                    {index + 1 !== array.length && <Divider/>}
                  </div>
                ))
              }
            </List>
          }

          <Avatar onClick={onClickAvatar} children={getNameInitials(getName())} sx={{width: 35, height: 35, bgcolor: 'white', color: '#006DFF', fontFamily: 'IBMPlexSans-Bold'}}/>
          {showAccountList &&
            <List ref={avatarRef} sx={{position: 'absolute', right: '0', top: '70px', bgcolor: 'white', zIndex: '10', borderRadius: BORDER_RADIUS, boxShadow: 3}}>
              <ListItem disablePadding>
                <ListItemButton sx={{backgroundColor: 'rgba(147, 153, 157, 0.1)'}}>
                  <ListItemText primary={getEmail()}/>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => window.location.pathname = '/my-account'}>
                  <ListItemText primary='My account' />
                </ListItemButton>
              </ListItem>

              {isMaster() ? (
                <ListItem disablePadding>
                  <ListItemButton onClick={() => window.location.pathname = '/admin-console'}>
                    <ListItemText primary='Admin Console' />
                  </ListItemButton>
                </ListItem>
              ) : (
                <ListItem disablePadding>
                  <ListItemButton onClick={() => window.location.pathname = '/invitations'}>
                    <ListItemText primary='Invitations' />
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem disablePadding>
                <ListItemButton onClick={() => logout()}>
                  <ListItemText primary='Log out' />
                </ListItemButton>
              </ListItem>
            </List>
          }
        </div>
      </div>

      {openModal ? 
        <Box sx={{
          paddingTop: '70px',
          flexGrow: 1,
          color: '#ffffff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px'
        }}>
          <h2>Checking your account...</h2>
          <CircularProgress color='inherit'/>
        </Box> :
        <Outlet context={{
          projects,
          sneakbar: {
            open: openSnackbar,
            setOpen: setOpenSnackbar,
            message: messageSnackbar,
            setMessage: setMessageSnackbar,
            severity: severitySnackbar,
            setSeverity: setSeveritySnackbar
          }
        }}/>
      }

      <Dialog open={dialogOpen} onClose={onCloseDialog} scroll='paper' fullWidth>
        <DialogTitle>{dialogContent.title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText style={{whiteSpace: 'pre'}} ref={descriptionElementRef} tabIndex={-1}>{dialogContent.description}</DialogContentText>
        </DialogContent>
      </Dialog>

      <div className={styles.alert}>
        <Alert open={openSnackbar} setOpen={setOpenSnackbar} message={messageSnackbar} severity={severitySnackbar}/>
      </div>
    </div>
  );
}

export default Layout;