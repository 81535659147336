import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {Box, Skeleton} from '@mui/material';

import Athena from './Athena';
import Darknet from './Darknet';
import Footer from '../Footer';
import { getAthenaQuotas } from '../../requests';

import styles from '../../styles/MyAccount.module.scss';

function MyAccount(props) {
  const [athenaRows, setAthenaRows] = useState([]);
  const [quotas, setQuotas] = useState({});

  const {sneakbar} = useOutletContext();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAthenaQuotas().then(response => {
      setAthenaRows(response.athena);
      setQuotas(Object.keys(response.quotas).sort().reduce(
        (obj, key) => {
          if (key.startsWith('am_')) {
            const resource = key.substring(3);
            obj['[Athena] ' + resource] = response.quotas[key];
          } else obj[key] = response.quotas[key];
          return obj;
        },
        {}
      ));

      setLoading(false);
    }).catch(error => {
      sneakbar.setMessage(error.response?.data?.error_message || 'Failed to fetch quotas.');
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: ''}}>
      <Box>
        <Skeleton variant='text' width='30%' sx={{ fontSize: '1.5em', bgcolor: 'rgba(255, 255, 255, 0.2)', margin: '20px 20px 10px 80px' }} animation='wave'/>
        <Skeleton variant='rounded' height={250} animation='wave' sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)' }}/>
      </Box>
      <Box>
        <Skeleton variant='text' width='30%' sx={{ fontSize: '1.5em', bgcolor: 'rgba(255, 255, 255, 0.2)', margin: '20px 20px 10px 80px' }} animation='wave'/>
        <Skeleton variant='rounded' height={150} animation='wave' sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)' }}/>
      </Box>
      <Box>
        <Skeleton variant='text' width='30%' sx={{ fontSize: '1.5em', bgcolor: 'rgba(255, 255, 255, 0.2)', margin: '20px 20px 10px 80px' }} animation='wave'/>
        <Skeleton variant='rounded' height={150} animation='wave' sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)' }}/>
      </Box>
    </Box>
  ) : (
    <>
      <div className={styles.myAccount}>
        <Athena rows={athenaRows}/>

        {Object.keys(quotas).map(key => (
          <Darknet key={key} title={key.charAt(0).toUpperCase() + key.substring(1)} rows={quotas[key]}/>
        ))}
      </div>

      <Footer/>
    </>
  );
}

export default MyAccount;