import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import _kc from './keycloak';
import { isMaster } from './requests';
import Home from './pages/Home';
import Layout from './pages/Layout';
import Athena from './pages/projects/Athena';
import UndergroundTI from './pages/projects/UndergroundTI';
import WaybackMachine from './pages/projects/WaybackMachine';
import Invitations from './pages/Invitations';
import MyAccount from './pages/my-account/MyAccount';
import Contact from './pages/Contact';
import AdminConsole from './pages/admin-console/AdminConsole';
import PageNotFound from './pages/PageNotFound';
import './fonts/IBMPlexSans-Regular.ttf';
import './fonts/IBMPlexSans-Bold.ttf';

_kc.init({
  onLoad: 'login-required',
  checkLoginIframe: false
}).then(() =>
  axios.interceptors.request.use((config) =>
    _kc.token ?
      _kc.updateToken(5)
        .then(() => {
          config.headers.Authorization = `Bearer ${_kc.token}`;
          return Promise.resolve(config);
        }) :
      config
  )
).then(() =>
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={{}}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<Home />} />
              {isMaster() ?
                <Route path='admin-console' element={<AdminConsole />} /> :
                <Route path='invitations' element={<Invitations />} />
              }
              <Route path='my-account' element={<MyAccount />} />
              <Route path='athena' element={<Athena />} />
              <Route path='uti' element={<UndergroundTI />} />
              <Route path='wayback-machine' element={<WaybackMachine />} />
              <Route path='contact' element={<Contact />} />
              <Route path='*' element={<PageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>,
  document.getElementById('root')
  )
);
