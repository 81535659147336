import React, {useState} from 'react';
import {
  Box,
  Collapse,
  IconButton, LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Row(props) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{width: '50px'}}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component='th' scope='row' sx={{width: '50px', fontWeight: 'bold'}}>{props.row.service}</TableCell>
        <TableCell/>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Fetcher</TableCell>
                    <TableCell>Quota</TableCell>
                    <TableCell>Used</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.row.fetchers.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{width: '50px', fontWeight: 'bold'}} component='th' scope='row'>{historyRow.fetcher}</TableCell>
                      <TableCell sx={{width: '200px'}}>{historyRow.quota + ' requests/month'}</TableCell>
                      <TableCell align='right'>
                        <Box display='flex' alignItems='end'>
                          <Box width='100%' mr={1}>
                            <Box display='flex' justifyContent='space-around'>
                              <Typography variant='caption' gutterBottom component='div'>{historyRow.used + ' used'}</Typography>
                              <Typography variant='caption' gutterBottom component='div'>{historyRow.quota - historyRow.used + ' remaining'}</Typography>
                            </Box>
                            {/* <ThemeProvider theme={theme}> */}
                            <LinearProgress variant='determinate' value={100 * historyRow.used / historyRow.quota}/>
                            {/* </ThemeProvider> */}
                          </Box>
                          <Typography variant='body2' color='textSecondary'>{Math.round(100 * historyRow.used / historyRow.quota).toFixed(2) + '%'}</Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Athena(props) {
  return (
    <>
      <h2>Athena</h2>

      <TableContainer component={Paper} sx={{boxShadow: 3}}>
        <Table aria-label='collapsible table'>
          <TableBody>
            {props.rows.map((row, index) => (
              <Row key={index} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Athena;