import validator from 'validator';

export const API = process.env.REACT_APP_URL_BACKEND || 'http://lea-backend-microservice.darknet.bitdefender.biz/api/v1';
export const TYPES_PATH = '/getTypes';
export const FEEDBACK_PATH = '/storeFeedback';
export const GETFEEDBACKS_PATH = '/getFeedbacks';
export const FETCH_QUOTAS_PATH = '/requests';

export const QUOTAS_PATH = '/quotas';

// invitations
export const INVITE_API = process.env.REACT_APP_URL_INVITE || 'http://invite.darknet.bitdefender.biz/api/v1';
export const CHECK_ACCOUNT_PATH = '/check_account';
export const INVITE_USER_PATH = '/invite_user';
export const INVITES_PATH = '/invites';
export const RESEND_EMAIL = '/reinvite_user';
export const INVITATION_PENDING = 0;
export const INVITATION_ACCEPTED = 1;
export const INVITATION_CANCELED = 2;

export const URL_SHORTENER = 'http://timetravel.darknet.bitdefender.biz/pp/api/v1/url_shortener';


export const ATHENA_URL = process.env.REACT_APP_URL_ATHENA || 'https://v3.athena.bitdefender.biz';
export const UTI_URL = process.env.REACT_APP_URL_UTI || 'http://darknet.bitdefender.biz';
export const WAYBACK_MACHINE_URL = process.env.REACT_APP_URL_WAYBACK_MACHINE || 'http://timetravel.darknet.bitdefender.biz';

export const MASTER_ROLE = 'master_invite';

export const ADMIN_CONSOLE_DASHBOARD = 'Dashboard';
export const ADMIN_CONSOLE_USERS = 'Users';
export const ADMIN_CONSOLE_ORGS = 'Organizations';
export const ADMIN_CONSOLE_FEEDBACK = 'Feedback';
export const ADMIN_CONSOLE_NOTIFICATIONS = 'Notifications';
export const ADMIN_CONSOLE_RBAC = 'Access Control';

export const CONTACT_EMAIL = 'acosoi@bitdefender.com';

export const TWITTER_URL = 'https://twitter.com/bitdefender';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/bitdefender';
export const FACEBOOK_URL = 'https://www.facebook.com/bitdefender';
export const YOUTUBE_URL = 'https://www.youtube.com/bitdefender';

export const ATHENA_PROJECT_NAME = 'Athena';
export const UTI_PROJECT_NAME = 'Underground Threat Intelligence';
export const WBM_PROJECT_NAME = 'Wayback Machine';

export const ATHENA_PROJECT_PATH = '/athena';
export const UTI_PROJECT_PATH = '/uti';
export const WBM_PROJECT_PATH = '/wayback-machine';

export const UTI_RESULTS_PER_PAGE = 20;

let PAGES = {};
PAGES[ATHENA_PROJECT_NAME] = ATHENA_PROJECT_PATH;
PAGES[UTI_PROJECT_NAME] = UTI_PROJECT_PATH;
PAGES[WBM_PROJECT_NAME] = WBM_PROJECT_PATH;
export { PAGES };

export const types = {
  'default': {
    name: 'default',
    match: (value) => false,  // TODO
  },
  '*': {
    name: 'all types',
    match: (value) => false
  },
  'name': {
    name: 'name',
    match: (value) => value.match(/^([A-Z]\w+(?=[\s-][A-Z])(?:[\s-][A-Z]\w*){1,2})$/),
  },
  'first_name': {
    name: 'first name',
    match: (value) => false
  },
  'middle_name': {
    name: 'middle name',
    match: (value) => false
  },
  'last_name': {
    name: 'last name',
    match: (value) => false,
  },
  'username': {
    name: 'username',
    match: (value) => false, // value.match(/^(?=[a-zA-Z][a-zA-Z0-9._]{7,19}$)(?!.*[_.]{2})[^_.].*[^_.]$/),
  },
  'email': {
    name: 'email',
    match: (value) => validator.isEmail(value),
  },
  'email_provider': {
    name: 'email provider',
    match: (value) => false,
  },
  'email_username': {
    name: 'email username',
    match: (value) => validator.isEmail(value),
  },
  'phone_number': {
    name: 'phone number',
    match: (value) => validator.isMobilePhone(value),
  },
  'phone': {  // TODO delete
    name: 'phone',
    match: (value) => validator.isMobilePhone(value),
  },
  'user_id': {
    name: 'user id',
    match: (value) => false
  },
  'online_account': {
    name: 'online account',
    match: (value) => false,
  },
  'pipl_person': {
    name: 'person',
    match: (value) => false,
  },
  'pipl_person_partial_match': {
    name: 'partial matched person',
    match: (value) => false,
  },
  'pipl_person_related': {
    name: 'related person',
    match: (value) => false,
  },
  'continent': {
    name: 'continent',
    match: (value) => false,  // TODO
  },
  'country': {
    name: 'country',
    match: (value) => false,  // TODO
  },
  'postal_code': {
    name: 'postal code',
    match: (value) => false,
  },
  'address': {
    name: 'address',
    match: (value) => false,
  },
  'city': {
    name: 'city',
    match: (value) => false,
  },
  'fax': {
    name: 'fax',
    match: (value) => false,
  },
  'data_breach': {
    name: 'data breach',
    match: (value) => false,  // TODO
  },
  'asn': {
    name: 'asn',
    match: (value) => value.match(/^(^AS)?[0-9]+$/)
  },
  'onion': {
    name: 'onion',
    match: (value) => value.match(/^[a-z0-9]{56}(.onion)?$/)
  },
  'domain': {
    name: 'domain',
    match: (value) => value.match(/^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/),
  },
  'domain_port': {
    name: 'domain port',
    match: (value) => false,  // TODO
  },
  'ip': {
    name: 'ip',
    match: (value) => validator.isIP(value),
  },
  'ip_port': {
    name: 'IP port',
    match: (value) => false,  // TODO
  },
  'ip_class': {
    name: 'ip class',
    match: (value) => value.match(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}0\/24$/),
  },
  'isp': {
    name: 'isp',
    match: (value) => false,  // TODO
  },
  'organization_name': {
    name: 'organization name',
    match: (value) => false,  // TODO
  },
  'url': {
    name: 'url',
    match: (value) => value.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/),
  },
  'md5': {
    name: 'md5',
    match: (value) => value.match(/^[a-fA-F0-9]{32}$/),
  },
  'sha1': {
    name: 'sha1',
    match: (value) => value.match(/^[0-9a-fA-F]{40}$/),
  },
  'sha256': {
    name: 'sha256',
    match: (value) => value.match(/^[0-9a-fA-F]{64}$/),
  },
  'http_header_etag': {
    name: 'http header etag',
    match: (value) => false,
  },
  'cve': {
    name: 'cve',
    match: (value) => value.match(/^cve-[0-9]{4}-[0-9]{4,}$/),
  },
  'cwe': {
    name: 'cwe',
    match: (value) => false,
  },
  'msb': {
    name: 'msb',
    match: (value) => false,
  },
  'bid': {
    name: 'bid',
    match: (value) => false,
  },
  'osvdb': {
    name: 'osvdb',
    match: (value) => false,
  },
  'software_product': {
    name: 'software product',
    match: (value) => false,
  },
  'software_product_version': {
    name: 'software product version',
    match: (value) => false,
  },
  'software_vendor': {
    name: 'software vendor',
    match: (value) => false,
  },
  'tls_certificate_sha1': {
    name: 'tls certificate sha1',
    match: (value) => false,
  },
  'document': {
    name: 'document',
    match: (value) => false,
  },
  'cryptocurrency': {
    name: 'cryptocurrency',
    match: (value) => {
      return value.match(/^btc:[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/)
        || value.match(/^xmr:4[0-9AB][123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{93}$/)
        || (value.length >= 27 && value.length <= 34 && value.match(/^(1|3|bc1)[a-zA-Z0-9]*$/))
    },
  },
  'ethereum-address': {
    name: 'ethereum-address',
    match: (value) => value.match(/^0x[a-fA-F0-9]{40}$/),
  },
  'cryptocurrency_tx': {
    name: 'cryptocurrency tx',
    match: (value) => false,
  },
  'keyword': {
    name: 'term',
    match: (value) => true,
  },
};

export const BORDER_RADIUS = '20px';