import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import ActivityCalendar from "react-activity-calendar";
import Tree from "react-d3-tree";
import {
  Box,
  Button,
  Collapse,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  DialogTitle,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function Organizations(props) {
  const [displayOrg, setDisplayOrg] = useState("All organizations");
  const [displayDate, setDisplayDate] = useState("");
  const [displayYear, setDisplayYear] = useState(new Date().getFullYear());
  const [currentOrg, setCurrentOrg] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [treeOrg, setTreeOrg] = useState("");
  const [treeOpen, setTreeOpen] = useState(false);

  function fetchYears() {
    let years = [];
    for (let i = 2023; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }

  function onSelectOrg(event) {
    if (event.target.value === "All organizations") {
      setDisplayOrg("All organizations");
      setCurrentOrg(props.orgs.map((row) => row.name));
      props.refreshOrgActivity(
        props.orgs.map((row) => row.name),
        displayYear
      );
      return;
    }
    setDisplayOrg(event.target.value);
    setCurrentOrg([event.target.value]);
    props.refreshOrgActivity([event.target.value], displayYear);
  }
  function onSelectYear(event) {
    setDisplayYear(event.target.value);
    props.refreshOrgActivity(currentOrg, event.target.value);
  }

  function getProjectsUsed(date) {
    for (let i = 0; i < props.orgActivity.data.length; i++) {
      if (props.orgActivity.data[i].date === date) {
        let res = [];
        for (let j = 0; j < props.orgActivity.data[i].projects.length; j++) {
          res.push(
            `${props.orgActivity.data[i].projects[j].Count} requests in ${props.orgActivity.data[i].projects[j].Name}\n`
          );
        }
        return res;
      }
    }
    return [];
  }
  function onDateClick(activity) {
    setDisplayDate(activity.date);
    props.refreshUserReqs(currentOrg, activity.date);
    setDialogOpen(true);
  }
  function onCloseDialog() {
    setDialogOpen(false);
  }
  function onCloseTree() {
    setTreeOpen(false);
    setTreeOrg("");
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <circle
        r="15"
        x="-10"
        onClick={toggleNode}
        fill={(() => {
          if (nodeDatum.attributes?.status === "Active") {
            return "green";
          } else if (nodeDatum.attributes?.status === "Inactive") {
            return "red";
          } else {
            return "blue";
          }
        })()}
      />
      <text fill="black" strokeWidth="0.4" x="20">
        {nodeDatum.name}
      </text>
      {nodeDatum.attributes?.status && (
        <text fill="black" x="20" dy="20" strokeWidth="0.1">
          Status: {nodeDatum.attributes?.status}
        </text>
      )}
    </g>
  );

  React.useEffect(() => {
    setCurrentOrg(props.orgs.map((row) => row.name));
  }, []);

  return (
    <>
      <Dialog onClose={onCloseDialog} open={dialogOpen}>
        <DialogTitle sx={{ textAlign: "center" }}>
          Requests on {displayDate}
        </DialogTitle>
        <DialogContent>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: 0, borderRadius: "20px" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ "td, th": { border: 0 } }}>
                  <TableCell sx={{ fontWeight: "bold" }}>User</TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Resource
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.userReqs.map((row, index) => (
                  <TableRow
                    key={index}
                    hover={true}
                    sx={{ "td, th": { border: 0 }, cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      {row.user}
                    </TableCell>
                    <TableCell align="left">{row.resource}</TableCell>
                    <TableCell align="left">{row.action}</TableCell>
                    <TableCell align="left">
                      {new Date(row.time * 1000).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Dialog onClose={onCloseTree} open={treeOpen} fullWidth="xl" maxWidth>
        <DialogTitle sx={{ textAlign: "center" }}>
          {capitalizeFirstLetter(treeOrg.slice(4))} Organization Tree
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "70vh",
              bgcolor: "background.paper",
            }}
          >
            <Tree
              data={props.orgTree}
              collapsible={true}
              renderCustomNodeElement={renderRectSvgNode}
              orientation="vertical"
              translate={{ x: 850, y: 100 }}
              nodeSize={{ x: 300, y: 100 }}
            ></Tree>
          </Box>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "40vw",
        }}
      >
        <Select
          value={displayOrg}
          style={{ marginRight: "20px", backgroundColor: "white" }}
          onChange={onSelectOrg}
        >
          <MenuItem value={"All organizations"}>All organizations</MenuItem>
          {props.orgs.map((row, index) => (
            <MenuItem key={index} value={row.name}>
              {capitalizeFirstLetter(row.name.slice(4))}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={displayYear}
          style={{ backgroundColor: "white" }}
          onChange={onSelectYear}
        >
          {fetchYears().map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <ActivityCalendar
        data={props.orgActivity.calendar}
        style={{ margin: "auto" }}
        showWeekdayLabels
        eventHandlers={{
          onClick: (event) => (activity) => {
            onDateClick(activity);
          },
        }}
        labels={{
          totalCount: `{{count}} activities in ${displayYear}`,
        }}
        renderBlock={(block, activity) => (
          <Tooltip
            title={
              <div>
                <p>{`${activity.count} requests on ${activity.date}`}</p>
                {getProjectsUsed(activity.date).map((row, index) => (
                  <p key={index}>{row}</p>
                ))}
              </div>
            }
          >
            {block}
          </Tooltip>
        )}
      />

      <TableContainer
        component={Paper}
        sx={{ boxShadow: 0, borderRadius: "20px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ "td, th": { border: 0 } }}>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                First user
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Created at
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Last Seen
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Total Logins
              </TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.orgs.map((row, index) => (
              <TableRow
                key={index}
                hover={true}
                sx={{ "td, th": { border: 0 }, cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  {capitalizeFirstLetter(row.name.slice(4))}
                </TableCell>
                <TableCell align="left">{row.firstInvite}</TableCell>
                <TableCell align="left">{row.createdAt}</TableCell>
                <TableCell align="left">{row.lastSeen}</TableCell>
                <TableCell align="left">{row.totalLogins}</TableCell>
                {/* <TableCell align="left">{row.numUsers}</TableCell> */}
                <TableCell align="left" sx={{ padding: 0 }}>
                  <Button
                    onClick={() => {
                      props.refreshOrgTree(row.name);
                      setTreeOrg(row.name);
                      setTreeOpen(true);
                    }}
                  >
                    <MoreHorizIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Organizations;
