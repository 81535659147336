import validator from 'validator';

/**
 * @param {Object[]} fields
 * @param {anything} fields[].value to validate
 * @param {string} fields[].type of the value
 * @param {boolean} fields[].empty true if the value can pe empty - not required
 * @returns {string[]} errors if there are any
 */
export function validate(fields) {
  let errors = [];
  const len = fields.length;
  for (let i = 0; i < len; i++) {
    const {value, type, empty} = fields[i];
    errors.push('');

    if ((!empty && !value) || value === undefined) {
      errors[i] = 'Empty field ' + type;
      continue;
    }

    switch (type) {
      case 'email':
        if (!validator.isEmail(value)) errors[i] = 'Not a valid email';
        break;
      default:
        errors[i] = 'Unknown error';
        break;
    }
  }
  return errors;
}

export function cutStr(str, n) {
  return str.length < n ? str : str.slice(0, n - 3) + '...';
}