import React, { useEffect, useState, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from 'styled-components';

import Footer from './Footer';
import { getNoInvitations, getPackage, getInvitations, sendInvitation, cancelInvitation, resendInvitationEmail } from '../requests';
import { validate } from '../utils';
import { BORDER_RADIUS, INVITATION_CANCELED, INVITATION_PENDING } from '../settings';

import styles from '../styles/Invitations.module.scss';

const InvitationHeader = styled.div`
  &&& {
    background-color: ${props => props.status === 0 ? 'rgb(255, 236, 0, 0.3)' : props.status === 1 ? 'rgb(0, 193, 190, 0.3)' : 'rgb(147, 153, 157, 0.3)'}
  }
`;

const USER_DECISION_AGREE = true;
const USER_DECISION_DISAGREE = false;

function Invitations(props) {
  const [email, setEmail] = useState('');
  const [invitations, setInvitations] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [messageDialog, setMessageDialog] = useState('message');
  const [userDecisionDialog, setUserDecisionDialog] = useState(USER_DECISION_DISAGREE);
  const userID = useRef(null);

  const { sneakbar } = useOutletContext();

  async function onClickSendInvitation(event) {
    if (!email) return;
    let err = validate([{value: email, type: 'email'}]);
    err = err.filter(e => e !== '');
    if (err.length !== 0) {
      sneakbar.setMessage('Please enter a valid email.');
      sneakbar.setSeverity('warning');
      sneakbar.setOpen(true);
      return;
    }

    const pendingInvitations = invitations.filter(invitation => invitation.status === INVITATION_PENDING).length;
    const availableInvitations = getNoInvitations() - pendingInvitations;
    if (!availableInvitations) {
      const message = 'You have used up all the invitations. ' + (pendingInvitations ?
        'Cancel any pending one in order to get a new invitation.' :
        'Contact us to get a new one!');
      sneakbar.setMessage(message);
      sneakbar.setSeverity('warning');
      sneakbar.setOpen(true);
      return;
    }

    try {
      const invitation = await sendInvitation(email);
      setInvitations(invitations => [invitation].concat(invitations));
      setEmail('');
    } catch (error) {
      sneakbar.setMessage(error.response?.data?.error_message || error.message || 'Failed to invite ' + email);
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
    }
  }

  function onClickResendInvitationEmail(email) {
    return (event) => {
      resendInvitationEmail(email).then(response => {
        sneakbar.setMessage('A new invitation has been sent to ' + email);
        sneakbar.setSeverity('success');
        sneakbar.setOpen(true);
      }).catch((error) => {
        sneakbar.setMessage(error.response?.data?.error_message || error.message || 'Failed to resend email to ' + email);
        sneakbar.setSeverity('error');
        sneakbar.setOpen(true);
      });
    };
  }

  function onClickCancelInvitation(id, email) {
    return (event) => {
      setMessageDialog('Are you sure you want to cancel the invitation to ' + email + '?');
      setOpenDialog(true);
  
      userID.current = id;
    };
  }

  function onCloseDialog(event) {
    setOpenDialog(false);
  }

  function onClickButtonDialog(userDecision) {
    return (event) => {
      setUserDecisionDialog(userDecision);
      onCloseDialog();
    };
  }

  //   function onClickSendInvitationAgain(email) {
  //   return (event) => {
  //     // setMessageDialog('Are you sure you want to send again an invitation to ' + email + '?');
  //     // setOpenDialog(true);
  //     // console.log(userDecisionDialog)
  //
  //     sendInvitation(email).then(response => {
  //       setInvitations(invitations => {
  //         let newInvitations = [];
  //         for (let invitation of invitations) {
  //           if (invitation.email !== email) newInvitations.push(invitation);
  //           else newInvitations.push({...invitation, status: INVITATION_PENDING});
  //         }
  //         return newInvitations;
  //       });
  //     })
  //     .catch(error => {
  //       sneakbar.setMessage(error.response?.data?.error_message || error.message || 'Failed to invite ' + email);
  //       sneakbar.setSeverity('error');
  //       sneakbar.setOpen(true);
  //     });
  //   };
  // }

  // function onUpgradeInvitation() {
  //   sneakbar.setMessage('Feature not available yet');
  //   sneakbar.setSeverity('info');
  //   sneakbar.setOpen(true);
  // }

  useEffect(() => {
    if (openDialog || userDecisionDialog === USER_DECISION_DISAGREE) return;

    cancelInvitation(userID.current).then(response => {
      setInvitations(invitations => invitations.map(invitation => {
        if (invitation.id !== userID.current) return invitation;
        let newInvitation = {...invitation};
        newInvitation.status = INVITATION_CANCELED;

        return newInvitation;
      }));
    }).catch((error) => {
      sneakbar.setMessage(error.response?.data?.error_message || error.message || 'Failed to delete the invitation');
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
    })
  }, [openDialog, sneakbar, userDecisionDialog]);

  useEffect(() => {
    getInvitations().then(response => {
      setInvitations(response);
    }).catch(error => {
      sneakbar.setMessage(error.message);
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
    });
  }, [sneakbar]);

  return (
    <>
      <div className={styles.invitations}>
        <div className={styles.header}>
          <h1>Invitations</h1>
          <h2>You can invite up to <span>{getNoInvitations()}</span> employees from your organization to access the <span>{getPackage()}</span>.</h2>
        </div>

        <div className={styles.input}>
          <TextField variant='outlined' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} sx={{bgcolor: 'white','& fieldset': { border: 'none' }, borderRadius: BORDER_RADIUS, boxShadow: 1}} size='small' inputProps={{style: { paddingLeft: '20px' }}}/>
          <Button onClick={onClickSendInvitation} variant='contained' sx={{bgcolor: '#00C1BE', '&:hover': {bgcolor: '#00C1BE'}, textTransform: 'none', borderRadius: BORDER_RADIUS, boxShadow: 1}}>Send invitation</Button>
          {/* <Button onClick={onClickCancelInvitation('test')} variant='contained' sx={{bgcolor: '#00C1BE', '&:hover': {bgcolor: '#00C1BE'}, textTransform: 'none'}}>Test dialog</Button> */}
        </div>

        <div className={styles.list}>
          {invitations.map((invitation, index) => (
            <Box key={index} className={styles.invitation} sx={{borderRadius: BORDER_RADIUS, boxShadow: 3}}>
              <InvitationHeader status={invitation.status} className={styles.header}>
                {invitation.status === 0 && (
                  <>
                    <PendingIcon fontSize='large' sx={{color: 'rgb(0, 0, 0, 0.5)'}}/>
                    <p style={{color: 'rgb(0, 0, 0, 0.5)'}}>Pending</p>
                  </>
                )}
                {invitation.status === 1 && (
                  <>
                    <CheckCircleIcon fontSize='large' sx={{color: '#00C1BE'}}/>
                    <p style={{color: '#00C1BE'}}>Accepted</p>
                  </>
                )}
                {invitation.status === 2 && (
                  <>
                    <CancelIcon fontSize='large' sx={{color: '#707070'}}/>
                    <p style={{color: '#707070'}}>Deleted</p>
                  </>
                )}
              </InvitationHeader>

              <h3>{invitation.email}</h3>
              <p>Package</p>
              <p>{invitation.package}</p>
              <p>No. Invitations</p>
              <p>{invitation.noInvitations}</p>

              <div className={styles.footer}>
                {invitation.status === INVITATION_PENDING && (
                  <>
                    <Button onClick={onClickResendInvitationEmail(invitation.email)} variant='contained' sx={{bgcolor: '#FFEC00', '&:hover': {bgcolor: '#FFEC00'}, color: 'black', textTransform: 'none', borderRadius: BORDER_RADIUS, boxShadow: 1}}>Send again</Button>
                    <Button onClick={onClickCancelInvitation(invitation.id, invitation.email)} variant='contained' sx={{bgcolor: '#93999D', '&:hover': {bgcolor: '#93999D'}, textTransform: 'none', borderRadius: BORDER_RADIUS, boxShadow: 1}}>Delete</Button>
                  </>
                )}

                {/*{invitation.status === INVITATION_ACCEPTED &&*/}
                {/*  <Button onClick={onUpgradeInvitation} variant='contained' sx={{bgcolor: '#00C1BE', '&:hover': {bgcolor: '#00C1BE'}, textTransform: 'none'}}>Upgrade</Button>*/}
                {/*}*/}
                {/*{invitation.status === INVITATION_CANCELED &&*/}
                {/*  <Button onClick={onClickSendInvitationAgain(invitation.email)} variant='contained' sx={{bgcolor: '#93999D', '&:hover': {bgcolor: '#93999D'}, textTransform: 'none'}}>Send again</Button>*/}
                {/*}*/}
              </div>
            </Box>
          ))}
        </div>

        <Dialog open={openDialog} onClose={onCloseDialog} >
          <DialogTitle>{messageDialog}</DialogTitle>
          <DialogContent>
            <DialogContentText>{messageDialog}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClickButtonDialog(USER_DECISION_DISAGREE)}>Disagree</Button>
            <Button onClick={onClickButtonDialog(USER_DECISION_AGREE)} autoFocus>Agree</Button>
          </DialogActions>
        </Dialog>
      </div>

      <Footer/>
    </>
  );
}

export default Invitations;