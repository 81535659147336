import styles from '../../styles/ProjectPages.module.scss';

function UndergroundTI(props) {
  return (
    <div className={styles.project}>
      <h1>Underground Threat Intelligence</h1>

      <p>
        Unlocks intelligence hidden on darknet, deepweb, forums and chat services.
        <br/>
        Consume content either with scripts or by browsing historical data available in fast storage.
        <br/><br/>
        View web resources as they were seen in the past and query for content in present or historical context.
        <br/>
        Configure alerts to be notified when a keyword shows up in our feeds.
        <br/><br/>
        Request new resources to be crawled, indexed and presented to you.
      </p>
    </div>
  );
}

export default UndergroundTI;