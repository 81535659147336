import React from 'react';
import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from '@mui/material';

function Row(props) {
  // console.log(props)
  return (
    <React.Fragment>
      <TableRow>
        {/*<TableCell sx={{width: '50px', fontWeight: 'bold'}} component='th' scope='row'>{props.resource}</TableCell>*/}
        <TableCell sx={{width: '50px', fontWeight: 'bold'}} component='th' scope='row'>{props.action}</TableCell>
        <TableCell sx={{width: '200px'}}>{props.row.total + ' requests/month'}</TableCell>
        <TableCell align='right'>
          <Box display='flex' alignItems='end'>
            <Box width='100%' mr={1}>
              <Box display='flex' justifyContent='space-around'>
                <Typography variant='caption' gutterBottom component='div'>{props.row.used + ' used'}</Typography>
                <Typography variant='caption' gutterBottom component='div'>{props.row.total - props.row.used + ' remaining'}</Typography>
              </Box>
              {/* <ThemeProvider theme={theme}> */}
              <LinearProgress variant='determinate' value={100 * props.row.used / props.row.total}/>
              {/* </ThemeProvider> */}
            </Box>
            <Typography variant='body2' color='textSecondary'>{Math.round(100 * props.row.used / props.row.total).toFixed(2) + '%'}</Typography>
          </Box>
        </TableCell>
        <TableCell/>
      </TableRow>
    </React.Fragment>
  );
}

function Darknet(props) {
  return (
    <>
      <h2>{props.title}</h2>

      <TableContainer component={Paper} sx={{boxShadow: 3}}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Quota</TableCell>
              <TableCell>Used</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(props.rows).map(key => (
              <Row key={key} action={key} row={props.rows[key]} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Darknet;