import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  DialogTitle,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";

import { sendInvitationAsAdmin } from "../../requests";
import { INVITATION_ACCEPTED, INVITATION_PENDING } from "../../settings";

// TODO: use
// eslint-disable-next-line no-unused-vars
function Row(props) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: "50px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          sx={{ width: "50px", fontWeight: "bold" }}
        >
          {props.row.service}
        </TableCell>
        <TableCell />
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Fetcher</TableCell>
                    <TableCell>Quota</TableCell>
                    <TableCell>Used</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.row.fetchers.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{ width: "50px", fontWeight: "bold" }}
                        component="th"
                        scope="row"
                      >
                        {historyRow.fetcher}
                      </TableCell>
                      <TableCell sx={{ width: "200px" }}>
                        {historyRow.quota + " requests/month"}
                      </TableCell>
                      <TableCell align="right">
                        <Box display="flex" alignItems="end">
                          <Box width="100%" mr={1}>
                            <Box display="flex" justifyContent="space-around">
                              <Typography
                                variant="caption"
                                gutterBottom
                                component="div"
                              >
                                {historyRow.used + " used"}
                              </Typography>
                              <Typography
                                variant="caption"
                                gutterBottom
                                component="div"
                              >
                                {historyRow.quota -
                                  historyRow.used +
                                  " remaining"}
                              </Typography>
                            </Box>
                            {/* <ThemeProvider theme={theme}> */}
                            <LinearProgress
                              variant="determinate"
                              value={(100 * historyRow.used) / historyRow.quota}
                            />
                            {/* </ThemeProvider> */}
                          </Box>
                          <Typography variant="body2" color="textSecondary">
                            {Math.round(
                              (100 * historyRow.used) / historyRow.quota
                            ).toFixed(2) + "%"}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Users(props) {
  const [newEmail, setNewEmail] = useState("");
  const [newOrg, setNewOrg] = useState("");
  const [newDomain, setNewDomain] = useState("");

  // const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  // const openList = !!popoverAnchorEl;

  const [dialogOpen, setDialogOpen] = useState(false);

  const { sneakbar } = useOutletContext();

  function onClickAddUser(event) {
    setDialogOpen(true);
  }

  function inviteNewUser() {
    sendInvitationAsAdmin(newEmail, newOrg, newDomain)
      .then((response) => {
        setNewEmail("");
        setNewOrg("");
        setNewDomain("");
        setDialogOpen(false);
        props.refreshUsers();
      })
      .catch((error) => {
        sneakbar.setMessage(
          error.response?.data?.error_message || "Failed to invite user."
        );
        sneakbar.setSeverity("error");
        sneakbar.setOpen(true);
      });
  }

  function onCloseDialog(event) {
    setDialogOpen(false);
  }
  return (
    <>
      <Dialog onClose={onCloseDialog} open={dialogOpen}>
        <DialogTitle sx={{ textAlign: "center" }}>Invite new user</DialogTitle>
        <DialogContent
          sx={{
            minWidth: "400px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <TextField
            fullWidth
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            label="Email"
            variant="outlined"
            size="small"
            sx={{ marginTop: "10px", "& focus": { border: "none" } }}
          />
          <TextField
            fullWidth
            value={newOrg}
            onChange={(e) => setNewOrg(e.target.value)}
            label="Organization"
            variant="outlined"
            size="small"
          />
          <TextField
            fullWidth
            value={newDomain}
            onChange={(e) => setNewDomain(e.target.value)}
            label="Restricted email domain"
            variant="outlined"
            size="small"
          />

          <Button
            variant="contained"
            onClick={inviteNewUser}
            style={{ textTransform: "none" }}
          >
            Send Invite
          </Button>
          <Button
            variant="outlined"
            onClick={onCloseDialog}
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>

      <Button
        onClick={onClickAddUser}
        startIcon={<AddIcon />}
        style={{ backgroundColor: "white" }}
        variant="outlined"
      >
        Invite new user
      </Button>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: 0, borderRadius: "20px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ "td, th": { border: 0 } }}>
              <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Organization
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Roles
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Status
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Inviter
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Last Seen
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Total Logins
              </TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users.map((row, index) => (
              <TableRow
                key={index}
                hover={true}
                sx={{ "td, th": { border: 0 }, cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell align="left">{row.organization || "-"}</TableCell>
                <TableCell align="left">
                  {row.roles
                    ? row.roles.map((role, index) => (
                        <Chip
                          key={index}
                          label={role}
                          variant="outlined"
                          size="small"
                          sx={{ margin: "2px" }}
                        />
                      ))
                    : "-"}
                </TableCell>
                <TableCell align="left">
                  {row.status === INVITATION_PENDING
                    ? "Pending"
                    : row.status === INVITATION_ACCEPTED
                    ? "Accepted"
                    : "Canceled"}
                </TableCell>
                <TableCell align="left">{row.inviter}</TableCell>
                <TableCell align="left">{row.lastSeen}</TableCell>
                <TableCell align="left">{row.totalLogins}</TableCell>
                <TableCell align="left" sx={{ padding: 0 }}>
                  <Button>
                    <MoreHorizIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Users;
