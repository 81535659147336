import React, {useState} from 'react';
import { useOutletContext } from 'react-router-dom';
import { TextField, Button, Tooltip } from '@mui/material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EmailIcon from '@mui/icons-material/Email';

import Footer from './Footer';
import { sendFeedback } from '../requests';
import { BORDER_RADIUS } from '../settings';

import styles from '../styles/Contact.module.scss';

function Contact(props) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const {sneakbar} = useOutletContext();

  async function onClickSend(event) {
    if (!title && !description) return;
    if (!title) {
      sneakbar.setMessage('Please insert a title for your feedback');
      sneakbar.setSeverity('warning');
      sneakbar.setOpen(true);
      return;
    }
    if (!description) {
      sneakbar.setMessage('Please describe your issuse in the text area');
      sneakbar.setSeverity('warning');
      sneakbar.setOpen(true);
      return;
    }

    try {
      await sendFeedback(title, description);
      sneakbar.setMessage('Thank you for reaching out and providing us with valuable feedback');
      sneakbar.setSeverity('success');
      sneakbar.setOpen(true);
      setTitle('');
      setDescription('');
    } catch (error) {
      sneakbar.setMessage(error.response?.data?.error_message || 'Failed to send feedback');
      sneakbar.setSeverity('error');
      sneakbar.setOpen(true);
    }
  }

  return (
    <>
      <div className={styles.contact}>
        <div className={styles.header}>
          <h1>Contact us</h1>

          <div className={styles.list}>
            <Tooltip title='Complete the feedback below'>
              <div className={styles.contactMethod}>
                <ConfirmationNumberIcon fontSize='large'/>
                <p>Send feedback</p>
              </div>
            </Tooltip>

            <Tooltip title='acosoi@bitdefender.com'>
              <div className={styles.contactMethod} onClick={() => window.location.href = 'mailto:acosoi@bitdefender.com'}>
                <EmailIcon fontSize='large'/>
                <p>Send an email</p>
              </div>
            </Tooltip>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.form}>
            <TextField
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder='Title'
              variant='outlined'
              fullWidth
              inputProps={{style: { paddingLeft: '20px' }}}
              sx={{width: '100%', bgcolor: 'background.paper', '& fieldset': { border: 'none' }, boxShadow: 2, borderRadius: BORDER_RADIUS}}
              size='small'
            />

            <TextField
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder='Description'
              inputProps={{style: { paddingLeft: '5px' }}}
              sx={{height: '100%', bgcolor: 'background.paper', '& fieldset': { border: 'none' }, boxShadow: 2, borderRadius: BORDER_RADIUS}}
              fullWidth
              multiline
              rows='9'
            />

            <Button onClick={onClickSend} variant='contained' sx={{width: '100px', bgcolor: '#00C1BE', '&:hover': {bgcolor: '#00C1BE'}, textTransform: 'none', borderRadius: BORDER_RADIUS}}>send</Button>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  );
}

export default Contact;